import styled from "styled-components";

export const ContainerProjects = styled.div`
display:grid;
grid-template-columns: 1fr;
`
export const Div = styled.div`
position: relative;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
`
export const DivBlock = styled.div`
border-radius: 10px 10px 10px 10px;
width: 100%;
padding-bottom: 10%;
padding-top: 10%;
&:hover{
    opacity: 1;
}
@media(max-width: 1311px){
    padding-top: 0%;
}
`
export const H1 = styled.h1`
    text-align: center;
    color: black;
    font-size: 30px;
    padding-bottom: 50px;
    @media(max-width: 499px){
        font-size: 20px;
    }
`
export const P = styled.p`
color: black;
font-size: 25px;
padding: 10px;
text-align: center;
line-height: 35px;
padding-bottom: 50px;
@media(max-width: 499px){
    font-size: 20px;
}
@media(max-width: 414px){
    line-height: 25px;
}
@media(max-width: 403px){
    font-size: 20px;
    line-height: 25px;
}   
@media(max-width: 254px){
    font-size: 18px;
}
`