import styled from "styled-components";

export const ContainerAbout = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin-top: 2%;
width: 100%;
@media(max-width: 1458px){
    margin-top: 10%;
}
`
export const Div = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
margin-top: 6%;
margin-bottom: 10%;
margin-left: 2%;
margin-right: 2%;
@media(max-width: 880px){
    margin-top: 5%;
    margin-bottom: 5%;
}
`
export const BorderWhite = styled.div`
background-color: transparent;
color: white;
padding: 15px;
border-radius: 15px 15px 15px 15px;
width: 80%;
height: auto;
display: flex;
align-items:center;
justify-content:center;
text-align: center;
&:hover{
    border: 1px solid white;
    transform: scale(1.03);
}
@media(max-width: 1372px){
    margin-top: 5%;
}
@media(max-width: 654px){
    margin-top: 10%;
}
@media(max-width: 575px){
    margin-top: 15%;
}
@media(max-width: 489px){
    margin-top: 20%;
}
@media(max-width: 350px){
    margin-top: 5%;
}
@media(max-width: 271px){
    margin-top: 0%;
}
`
export const ContentAbout = styled.p`
line-height: 40px;
font-size: 25px;
font-weight: 300;
@media(max-width: 835px){
font-size: 22px;
}
@media(max-width: 575px){
    line-height: 35px;
    font-size: 20px;
}
`
export const Important = styled.span`
font-weight: 400;
`