import React from 'react'
import { Div, DivBlock, ContainerProjects } from "../../style/Portfolio"
import Caroussel from '../Caroussel';
import { recouvrementRes, capanna } from '../Data';
import "../../index.css"
import Navbar from '../nav/Nav';
import { MainTitleSectionDesign } from '../../style/General';
import FooterContact from '../FooterContact';
import ChangeLanguage from '../Context/ChangeLanguage';
import { useLanguage } from '../Context/LanguageContext.jsx';

function Residential() {
    const { language } = useLanguage()
    return (
        <>
            <Navbar />
            <ChangeLanguage />
            <ContainerProjects>
                <Div>
                    <DivBlock>
                        <MainTitleSectionDesign>{language === 'Fr' ? 'RESIDENTIEL' : 'RESIDENTIAL'}</MainTitleSectionDesign>
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <Caroussel images={capanna} />
                        </div>
                    </DivBlock>
                    <DivBlock>
                        <div data-aos="fade-right" data-aos-duration="1000">
                            <Caroussel images={recouvrementRes} />
                        </div>
                    </DivBlock>
                </Div>
            </ContainerProjects>
            <FooterContact />
        </>
    )
}

export default Residential