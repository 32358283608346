import React from 'react'
import { useRef } from 'react'
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, InputDiv, Input, Textarea, Button, ContainerContact } from "../style/Contact"
import { MainTitleSection } from "../style/General"
import Navbar from './nav/Nav';
import FooterContact from './FooterContact';
import ChangeLanguage from './Context/ChangeLanguage';
import { useLanguage } from './Context/LanguageContext.jsx';

function Contact() {
    const { language } = useLanguage()
    console.log(language)
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_SERVICE_EMAIL, process.env.REACT_APP_TEMPLATE_EMAIL, form.current, process.env.REACT_APP_ACCOUNT_EMAIL)

        e.target.reset();
        toast.success('Message envoyé !', {
            className: "toast-message"
        })

    };

    return (
        <>
            <ToastContainer />
            <Navbar />
            <ChangeLanguage />
            <ContainerContact className='test'>
                <MainTitleSection id="contact">Contact</MainTitleSection>
                <Form ref={form} onSubmit={sendEmail}>
                    <InputDiv>
                        <Input id='name' type="text" name='name' placeholder={language === 'Fr' ? 'Nom / Prénom' : 'Name / LastName'} required />
                        <Input id='email' type="email" name='email' placeholder='email' required />
                    </InputDiv>
                    <Textarea id='textarea' name="message" placeholder='Message' rows="7" required></Textarea>
                    <Button type='submit' className='btn btn-primary'>{language === 'Fr' ? 'Envoyer' : 'Send'}</Button>
                </Form>   
            </ContainerContact>
            <FooterContact />

        </>

    );
}

export default Contact