import "../style/carousel.css"

export const scalables = [
    {
        title: "scalables",
        image: "/images/design3.webp",
        contentEn: " Perforated sheet metal modules inspired by the world of fauna and flora",
        contentFr: " modules en tôle perforée inspirés par l’univers de la faune et de la flore",
        classContent:"cc-white",
    },
    {
        title: "scalables",
        image: "/images/design5.webp",
        contentEn: " Scalable Nature is a kit of modules that can be easily assembled together.",
        contentFr: " Scalable Nature est un kit de modules qui peuvent être simplement assemblés entre eux.",
        classContent:"cc-white",
    },
    {
        title: "scalables",
        image: "/images/design6.webp",
        contentEn: "Transform your interior and everyday objects by giving them a colorful and organic look. ",
        contentFr: "Transformez votre intérieur et vos objets du quotidien en leur donnant un aspect coloré et organique.",
        classContent:"cc-white",
    },
]
export const venti = [
    {
        title: "venti",
        image: "/images/venti4.jpg",
        contentEn: `Venti reinterprets the formal DNA of well-known design furniture`,
        contentFr: `Venti réinterprète l'ADN formel de
        mobiliers de design connu de tous`,
        classContent:"cc-white",
    },
    {
        title: "venti",
        image: "/images/venti2.jpg",
        contentEn: "This project is ultimately a tribute to Eames, Paulin, Noguchi, bringing a new dimension to their iconic objects.",
        contentFr: "Ce projet est finalement un hommage à Eames, Paulin, Noguchi, apportant à leurs objets iconiques, une nouvelle dimension.",
        classContent:"cc-white",
    },
    {
        title: "venti",
        image: "/images/venti3.jpg",
        contentEn: "The furniture collection opens a door to a new formal universe; floating, unpredictable, free...",
        contentFr: "L’ensemble de mobilier ouvre une porte vers un nouvel univers formel ; flottant, imprévisible, libre...",
        classContent:"cc-white",
    },
    {
        title: "venti",
        image: "/images/venti1.jpg",
        contentEn: " Offering more than a frozen object in time, but a light and dispersible atmosphere accessible to many.",
        contentFr: " proposant plus qu’un objet figé dans le temps, mais une atmosphère légère et dispersible au plus grand nombre.",
        classContent:"cc-white",
    },
]
export const prisma = [
    {
        title: "prisma",
        image: "/images/prisma2.jpg",
        contentEn: "The Prisma furniture range adapts to the various uses of the Chaudronneries de Montreuil.",
        contentFr: "La gamme de mobilier Prisma, s’adapte aux différents usages des Chaudronneries de Montreuil."
        ,
        classContent:"cc-white",
    },
    {
        title: "prisma",
        image: "/images/prisma3.jpg",
        contentEn: " With its triangular shape, the seating and tables will adapt to various event typologies.",
        contentFr: "  Par sa forme triangulaire, les assises et tables s’adapteront aux différentes typologies d’évènements. ",
        classContent:"cc-white",
    },
    {
        title: "prisma",
        image: "/images/prisma4.jpg",
        contentEn: "An infinite number of shape compositions is possible, in groups of 2, 3, 4, extending to the possibility of creating a set of tables for 6 people.",
        contentFr: "Une infinité de compositions de formes est donc possible, par groupe de 2, 3, 4 allant jusqu’à donner la possibilité de créer un ensemble de tables de 6 personnes.",
        classContent:"cc-white",
    },
    {
        title: "prisma",
        image: "/images/prisma5.jpg",
        contentEn: "Depending on the location – be it a meeting room, restaurant, or living space – the entire range can be easily customized by simply changing the orientation of the object. The collection of objects has been created, always adhering to the principle of a second skin, covering, and translucency effects.",
        contentFr: "En fonction du lieu, salle de réunion, restaurant, habitat ; toute la gamme peut se moduler facilement, simplement en changeant l’orientation de l’objet. La collection d’objets à été créée toujours en accompagnant le principe de seconde peau, de recouvrement et d’effets de translucidité",
        classContent: "cc-white",
    }
]
export const suresnes = [
    {
        titleFr: "mairie de suresnes",
        titleEn: "Suresnes mairie",
        image: "/images/suresnes1.jpg",
        contentEn: 
        "The project is based on three main axes: monumentality, highlighting the building's identity, and a circulation induced by the existing structure.",
        contentFr: "Le projet repose sur trois grands axes : la monumentalité, la mise en valeur de l’identité du bâtiment, et une circulation induite par la structure existante.",
        classContent:"cc-white",
    },
    {
        titleFr: "mairie de suresnes",
        titleEn: "Suresnes mairie",
        image: "/images/suresnes2.jpg",
        contentEn: 
        "The challenge of a dedicated administrative reception space in France is to represent French patriotic values by creating a monumental and grandiose project that invites the public inside the building.",
        contentFr: "L’enjeu d’un espace d’accueil dédié à l’administration en France est de représenter les valeurs patriotiques françaises en constituant un projet monumental, grandiose, qui appelle le public vers l’intérieur du bâtiment.",
        classContent:"cc-white",
    },
    {
        titleFr: "mairie de suresnes",
        titleEn: "Suresnes mairie",
        image: "/images/suresnes3.jpg",
        contentEn: 
        "A new staircase has been designed to offer a main flow in the verticality of the building, connecting from the ground floor to the first floor.",
        contentFr: "Un nouvel escalier a donc été dessiné, afin de proposer un flux principal dans la verticalité du bâtiment allant du rez -de -jardin au premier étage.",
        classContent:"cc-white",
    },
    {
        titleFr: "mairie de suresnes",
        titleEn: "Suresnes mairie",
        image: "/images/suresnes4.jpg",
        contentEn: 
        "The project also highlights a unique craftsmanship: painted decor, which is less expensive and more environmentally friendly than using real marble.",
        contentFr: "Le projet met également en valeur un savoir-faire unique : le décor peint, moins coûteux et plus écologique que l’usage du vrai marbre.",
        classContent:"cc-white",
    }
]
export const home = [
    {
        image: "/images/residential2.jpg",
    },
    {
        image: "/images/recoverRes4.jpg",
    },
    {
        image: "/images/librairie2.jpg",
    },
    {
        image: "/images/venti4.jpg",
    },

]
export const recouvrementPBC = [
    {
        titleFr: "recouvrement - le restaurant",
        titleEn: "recouvrement - the restaurant",
        image: "/images/residential1.jpg",
        contentEn: "By composing materials, colors and principles, the new spaces will be integrated into the identity of the visitor, and find a contrast between apparent structure and disorienting one.",
        contentFr: "Par la superposition de matériaux, couleurs et principes constructifs habituellement utilisés en façade...",
        classContent:"cc-white",
    },
    {
        title: "publics",
        image: "/images/residential2.jpg",
        contentEn:
        "The newly created spaces will integrate with the identity of the existing structure while creating a contrast between exposed and concealed elements.",
        contentFr: "les nouveaux espaces créés s’intégreront à l'identité de l’existant tout en créant un contraste entre structure apparente et dissimulée.",
        classContent:"cc-white",
    },
    {
        title: "publics",
        image: "/images/residential3.jpg",
        contentEn: "The Recouvrement project is a break between industrial and contemporary identity.",
        contentFr: "Le projet Recouvrement est une rupture entre identité industrielle et contemporaine.",
        classContent:"cc-white",
    },
    {
        title: "publics",
        image: "/images/residential4.jpg",
        contentEn: "The restaurant is designed to provide a new perspective on events at Chaudronneries de Montreuil through translucent elements, such as textiles or perforated metal.",
        contentFr: "Le restaurant est pensé de sorte à offrir une nouvelle observation des événements des Chaudronneries de Montreuil, par des dispositif translucides, tel que le textile ou la tôle perforée.",
        classContent:"cc-white",
    },
]
export const artificial = [
    {
        titleFr: "intelligence artificielles",
        titleEn: "Artificial Intelligences",
        image: "/images/artificial4.png",
        contentEn: "Project exhibited at the ST'ART 2022 Contemporary Art Fair in Strasbourg.",
        contentFr: "Projet exposé lors de la foire d’art contemporain de Strasbourg ST’ART 2022.",
        classContent:"cc-white",
    },
    {
        titleFr: "intelligence artificielles",
        titleEn: "Artificial Intelligences",
        image: "/images/artificial2.png",
        contentEn: "This project explores the relationship between creation and artificial intelligence. Designed by multiple AI systems.",
        contentFr: " Ce projet explore la relation entre création et intelligence artificielle. Conçu par plusieurs IA.",
        classContent:"cc-white",
    },
    {
        titleFr: "intelligence artificielles",
        titleEn: "Artificial Intelligences",
        image: "/images/artificial3.png",
        contentEn:"The visuals generated in the style of various designers represent a series of 42 innovative objects.",
        contentFr: "Les visuels générés à la manière de différents designers  représentent une série de 42 objets novateurs.",
        classContent:"cc-white",
    },
    {
        titleFr: "intelligence artificielles",
        titleEn: "Artificial Intelligences",
        image: "/images/artificial1.png",
        contentEn: "The board game is played in the style of a 'game of 7 families,' accessible to all, allowing players to become familiar with and understand the distinct identities of the 7 most influential creators.",
        contentFr: "Le jeu de société se joue à la manière d’un jeu de 7 familles, accessible à tous permettant de se familiariser et d’appréhender les identités distinctes des 7 créateurs les plus influents.",
        classContent:"cc-white",
    },
]

export const capanna = [
    {
        title: "capanna",
        image: "/images/capanna1.jpg",
        contentEn: "project realized in collaboration with Dedar. The construction of the cabin involves the use of a kit consisting of only 2 types of elements: horizontal systems and Dedar textile.",
        contentFr: "projet réalisé en collaboration avec Dedar. La réalisation de la cabane consiste en l’utilisation d’un kit composé de seulement 2 types d’éléments : des systèmes horizontaux, et du textile Dedar.",
        classContent:"cc-white",
    },
    {
        title: "capanna",
        image: "/images/capanna2.jpg",
        contentEn: "On this principle, any hotel room, regardless of its theme and typology, can be transformed through this system. It is essential today in the field of architecture or design to know how to compose with what the existing environment offers us.",
        contentFr: "Sur ce principe, toute chambre d’hôtel, quel que soit son univers et sa typologie, peut être transformée via ce dispositif.Il est aujourd’hui essentiel dans le domaine de l’architecture ou du design de savoir composer avec ce que l’existant nous offre",
        classContent:"cc-white",
    },
    {
        title: "capanna",
        image: "/images/capanna3.jpg",
        contentEn: "Rather than envisioning yet another new proposal, we believe here that it is possible to create with simplicity, drawing inspiration from the straightforward concept of a cabin.",
        contentFr: "plutôt que de repenser une énième nouvelle proposition, nous pensons ici qu’il est  possible de créer avec peu, prenant inspiration d’un concept simple qu’est la cabane.",
        classContent:"cc-white",
    },
]

export const recouvrementRes = [
    {
        titleFr: "recouvrement - la résidence d'artistes",
        titleEn: "recouvrement - the artists' residence",  
        image: "/images/recoverRes1.jpg",
        contentEn: "The tarpaulin is no longer just there for protection; it provides a selected luminous effect to give a specific materiality to the project.",
        contentFr: "La bâche n'est plus uniquement là pour protéger, elle apporte un effet lumineux choisi, pour donner une matérialité spécifique au projet",
        classContent:"cc-white",
    },
    {
        titleFr: "recouvrement - la résidence d'artistes",
        titleEn: "recouvrement - the artists' residence", 
        image: "/images/recoverRes2.jpg",
        contentEn: "While providing privacy and a sense of isolation for the residents of the residence...",
        contentFr: "Tout en procurant intimité et sensation d'isolement des habitants de la résidence...",
        classContent:"cc-white",
    },
    {
        titleFr: "recouvrement - la résidence d'artistes",
        titleEn: "recouvrement - the artists' residence", 
        image: "/images/recoverRes3.jpg",
        contentEn: "From the outside, passersby will encounter, at night, a luminous and translucent box.",
        contentFr: "Depuis l'extérieur, les passants feront face, de nuit, à une boîte lumineuse et translucide",
        classContent:"cc-white",
    },
    {
        titleFr: "recouvrement - la résidence d'artistes",
        titleEn: "recouvrement - the artists' residence", 
        image: "/images/recoverRes4.jpg",
        contentEn: "During the day, they will catch a glimpse of the metal structure blending with the vegetation of the interior gardens.",
        contentFr: "De jour, ils apercevront la structure métallique se mêlant à la végétation des jardins intérieurs",
        classContent:"cc-white",
    },
]
export const librairie = [
    {
        titleFr: "La librairie idéale",
        titleEn: "The ideal library",
        image: "/images/librairie1.jpg",
        contentEn: "Through this café-bookstore project, the space promotes the sharing of a common passion: reading and the publishing of books.",
        contentFr: "A travers ce projet de café-librairie, le lieu promeut le partage d’une passion commune : la lecture et l’édition de livres.",
        classContent:"cc-white",
    },
    {
        titleFr: "La librairie idéale",
        titleEn: "The ideal library",
        image: "/images/librairie2.jpg",
        contentEn: "By using corrugated cardboard and paper, these materials will be highlighted in a luxurious context, echoing the materials used by Gerhard Steidl in the production of his works.",
        contentFr: "Par l’utilisation du carton ondulé et du papier, ces matériaux seront mis en valeur dans un contexte luxueux, faisant écho aux matériaux qu’utilise Gehrart Steidl pour la fabrication de ses ouvrages.",
        classContent:"cc-white",
    },
    {
        titleFr: "La librairie idéale",
        titleEn: "The ideal library",
        image: "/images/librairie3.jpg",
        contentEn: "This bookstore offers to step out of the conventional retail space.",
        contentFr: "Cette librairie propose de sortir du lieu conventionnel de vente",
        classContent:"cc-white",
    },
    {
        titleFr: "La librairie idéale",
        titleEn: "The ideal library",
        image: "/images/librairie4.jpg",
        contentEn: "It's no longer just about consuming and buying books but about enjoying a beverage while reading something that appeals to us.",
        contentFr: "Il ne s'agit plus seulement de consommer et d’acheter des livres, mais de déguster une boisson autour d’une lecture qui nous plaise.",
        classContent:"cc-white",
    },
]